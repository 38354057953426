@media (max-width: 576px) {
  .customPopupDesign {
    width: 275px !important;
  }
  .addMenuImg {
    width: 240px !important;
    height: 90px !important;
  }
  .iFrameDisplay {
    height: 100px !important;
    width: 230px !important;
  }
  .timeDayInput {
    position: absolute;
    top: 41px;
    left: 10x;
    font-size: 12px;
  }
  .nav-bar1 .company-logo {
    width: 75px !important;
  }
  .menus-list {
    top: 98px !important;
  }
  .class-service-cont {
    select {
      width: 90%;
      margin: auto;
    }
  }
  .footer-one-inner-cont {
    li {
      text-align: center;
    }
  }
  .service-header {
    p {
      width: 85% !important;
      font-size: 14px !important;
    }
  }
  .tl-classified {
    .tl-box {
      .box {
        width: 100% !important;
      }
    }
  }
  .banner-search-cont input {
    padding: 6px 0 6px 18px !important;
  }
  .banner-search-cont svg {
    margin-right: 8px;
  }
  .banner-search-cont {
    width: 230px !important;
  }
  .contact-button {
    padding: 4px 6px !important;
    svg {
      font-size: 14px !important;
      margin-right: 3px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
  .result-txt {
    font-size: 13px !important;
    margin-left: 10px;
  }
  .rit-post-list {
    width: calc(100% - 42px);
    margin-left: 42px;
    .tl-tb-box {
      justify-content: space-between;
      button,
      p {
        font-size: 13px !important;
        margin: 0px !important;
      }
    }
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .banner-img-cont {
    height: 50%;
  }
  .rit-post-list {
    width: calc(100% - 42px);
    margin-left: 42px;
    .tl-tb-box {
      button,
      p {
        font-size: 13px !important;
        margin: 0px 10px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .checkBoxDesign {
    margin-top: 10px !important;
  }

  .error-state-otp {
    color: red !important;
    position: absolute !important;
    bottom: 75px !important;
    right: 0px !important;
    font-size: small !important;
    font-family: "f2" !important;
  }

  .number-container {
    position: relative;
    .error-state-login {
      color: red !important;
      position: absolute !important;
      bottom: -3px !important;
      right: 0px !important;
      font-size: small !important;
      font-family: "f2";
    }
  }
  .bread-crum-list {
    align-items: center !important;
    margin-right: 10px !important;
    button {
      font-size: 12px !important;
      svg {
        font-size: 12px !important;
      }
    }
    svg {
      font-size: 12px !important;
    }
    p {
      font-size: 12px !important;
    }
  }
  .tl-service {
    .service-box {
      .box {
        padding-bottom: 10px !important;
        padding-top: 30px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        width: 100% !important;
        margin: auto !important;
        .box-inner {
          width: 90%;
          .profile-img {
            width: 70px !important;
            height: 70px !important;
            img {
              margin-left: 0px !important;
            }
          }
          h4 {
            font-size: 13px !important;
          }
          h5 {
            font-size: 12px !important;
          }
          svg {
            font-size: 13px !important;
          }
          p {
            span {
              font-size: 11px !important;
            }
            font-size: 11px !important;
          }
          button {
            font-size: 11px !important;
            padding: 3px 10px !important;
            margin-bottom: 2px !important;
          }
        }
      }
    }
  }
  .service-detail-sec {
    h5 {
      font-size: 17px !important;
    }
    h6 {
      font-size: 12px !important;
    }
    p {
      font-size: 13px !important;
    }
    .desc-txt {
      font-size: 12px !important;
    }
  }
  .SecondFlex {
    .MobileNumberTextInput {
      margin-bottom: 13px !important;
    }
    .LoginFont {
      font-size: 12px !important;
      margin-bottom: 5px !important;
    }
    .LoginText {
      font-size: 19px !important;
      margin-bottom: 5px !important;
    }
  }
  .middle-footer-inner-cont {
    div {
      justify-content: center !important;
      min-height: 15px !important;
    }
    p,
    button {
      margin-right: 0 !important;
    }
  }
  .menus-list {
    left: auto !important;
    right: 5px !important;
    // width: auto !important;
    width: 150px !important;
  }
  .banner-img-cont {
    height: 70%;
  }
  .nav-bar1 .menus-list {
    .menu-categ-btn {
      padding: 10px 20px !important;
      width: 100%;
    }
    .sub-categ {
      height: auto !important;
    }
    .btn-cls {
      top: 22px !important;
    }
  }
  .tl-clasified-page {
    padding: 0 0% !important;
    .tl-classified-box {
      width: 100% !important;
    }
  }
  .tl-classif-tab {
    .tab-txt {
      button {
        font-size: 12px !important;
        padding: 3px 2px !important;
        margin: 0 !important;
        display: flex;
      }
    }
    .tab-txtServices {
      height: 20px;
      button {
        font-size: 12px !important;
        padding: 3px 2px !important;
        margin: 0 !important;
        display: flex;
      }
      .txtServices {
        height: 30px !important;
      }
    }
    .title-h4 {
      height: auto !important;
      p {
        font-size: 12px !important;
      }
      .line1 {
        width: 40px !important;
        height: 2px !important;
        margin-left: 0px !important;
      }
    }
    .more-btn {
      width: 65px !important;
      height: 20px !important;
      position: absolute !important;
      top: 8px !important;
      right: 10px !important;
      p {
        font-size: 11px !important;
      }
      svg {
        font-size: 11px !important;
      }
    }
    .tab-height {
      height: 20px !important;
    }
  }
  .sort-by {
    margin-right: 10px !important;
  }
  .tl-classified-box {
    .classified-box {
      .lft-img-box {
        width: 100% !important;
        // height: auto !important;
        img {
          margin: auto !important;
          width: 100% !important;
        }
      }
      .image-res {
        height: 200px !important;
      }
      .rit-box {
        margin-top: 8px !important;
        padding-left: 0px !important;
        h3 {
          font-size: 12px !important;
          font-family: "f4" !important;
        }
        h4 {
          font-size: 12px !important;
        }
        h5 {
          font-size: 11px !important;
          margin: 5px 0 !important;
        }
        .explore-btn {
          width: 121px !important;
          padding: 2px 9px !important;
          margin-top: 5px !important;
          p {
            font-size: 11px !important;
          }
          svg {
            font-size: 13px !important;
          }
        }
        .post-by {
          p {
            font-size: 11px !important;
          }
          svg {
            font-size: 13px !important;
          }
        }
      }
    }
  }
  .relate-post-tab {
    button {
      padding: 5px 13px !important;
      font-size: 15px !important;
      span {
        padding: 5px 3px !important;
        width: 130px !important;
      }
    }
  }
  .service-box {
    .box1 {
      width: 450px;
    }
  }
  .profile-left {
    position: absolute;
    overflow: hidden !important;
    transition: 0.2s all linear;
    .profile-img1 {
      opacity: 0 !important;
    }
    .profile-img2 {
      opacity: 1 !important;
    }
    .button1 {
      opacity: 0 !important;
      transition: 0.2s all linear;
    }
    h4 {
      font-size: 13px !important;
    }
  }
  .profile-right {
    padding: 10px 10px 10px 57px !important  ;
    h5 {
      font-size: 18px !important;
    }
  }
  .profile-left1 {
    width: 42px !important;
    transition: 0.2s all linear;
  }
  .SecondFlex {
    width: 100% !important;
  }
  .rit-box-jox {
    width: 100% !important;
    margin-top: 20px !important;
    h4 {
      margin-bottom: 10px !important;
    }
    h5 {
      margin-bottom: 13px !important;
    }
  }
  .job-box {
    .box-inner {
      .profile-img {
        height: 70px !important;
        width: 70px !important;
        // img {
        //   margin-left: -20px !important;
        // }
      }
      .rit-box-jox {
        h4 {
          font-size: 12px !important;
        }
        h5 {
          font-size: 12px !important;
        }
        p {
          font-size: 11px !important;
          span {
            font-size: 11px !important;
          }
        }
        button {
          font-size: 11px !important;
          padding: 3px 15px !important;
        }
        svg {
          font-size: 13px !important;
        }
      }
    }
  }
  .gallery-post-tl .gallery-text {
    font-size: 19px !important;
    padding: 7px !important;
    width: 113px !important;
    margin-bottom: 12px !important;
    margin-top: 25px !important;
  }

  //
  // .tl-service2 img {
  //   height: 100% !important;
  //   width: 100% !important;
  // }
}

@media (min-width: 768px) {
  .sub-categ {
    position: absolute;
  }
  .txtServices2 {
    height: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sub-categ {
    position: absolute;
    top: 30px !important;
    left: 0 !important;
  }
  .tl-classified-box {
    .classified-box {
      width: 100% !important;
    }
  }
  .service-box {
    .box1 {
      width: 450px;
    }
  }
  .service-detail-sec h5 {
    font-size: 20px !important;
  }
  .tl-classif-tab {
    .title-h4 {
      height: 25px !important;
      p {
        font-size: 13px !important;
      }
      .line1 {
        width: 33px !important;
        height: 2px !important;
        margin-left: 8px !important;
      }
    }
    .tab-height {
      height: 25px !important;
    }
    .more-btn {
      height: 25px !important;
      p {
        font-size: 13px !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .last-footer-inner-cont {
    div {
      text-align: center;
      margin: auto;
    }
  }
  .menus-list {
    width: 98%;
    position: absolute;
    left: 1%;
    top: 120px;
    z-index: 11;
    background: #fff;
  }
  .nav-bar-collp-cont {
    display: flex;
    flex-direction: column;

    ul {
      margin-right: auto !important;
    }
  }

  .nav-sign-in-cont {
    button {
      margin-right: auto;
    }
  }
  .top-design {
    .navbar-collapse {
      position: absolute;
      top: 82px;
      right: 10px;
      background: #fff;
      width: 300px;
      padding: 10px;
      z-index: 111;
    }
  }
  .banner-search-cont {
    width: 300px;
  }
  .banner-carousel-gallery {
    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }
    .carousel-inner {
      .left-carousel {
        width: 100%;
      }
      .cap-tion {
        width: 100%;
        margin-left: 0 !important;
        p {
          font-size: 13px !important;
        }
      }
    }
  }
  .profile-right {
    .form-groups {
      width: 100% !important;
      input,
      select {
        width: 100%;
      }
    }
  }
  .menus-list {
    overflow: hidden;
    transition: 0.2s all linear;
    height: 0;
  }
  .menus-list1 {
    height: auto !important;
  }
  .sort-by .show-text {
    padding: 2px 5px !important;
    font-size: 12px !important;
    svg {
      font-size: 14px;
    }
  }
  .submenu {
    display: none !important;
  }
  .submenu2 {
    display: none !important;
  }
  .submenu3 {
    display: none !important;
  }
  .submenu4 {
    display: none !important;
  }
  .submenu5 {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .without-carousel {
    width: 50%;
  }
  .banner-search-cont {
    input {
      width: 85%;
    }
  }

  .sub-categ {
    position: absolute;
  }
  .banner-search-cont {
    width: 500px;
    svg {
      margin-left: 30px;
    }
  }
  .banner-search-cont {
    width: 500px;
    svg {
      margin-right: 16px;
    }
  }
  .service-box {
    .box1 {
      width: 380px;
    }
  }
  .banner-carousel-gallery {
    .carousel-control-next {
      // left: 300px !important;
      left: 350px !important;
      right: auto !important;
    }
    .carousel-inner {
      .left-carousel {
        width: 350px;
      }
      .cap-tion {
        width: 650px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tl-clasified-page {
    width: 98% !important;
    .tl-classified-box {
      width: 100% !important;
    }
  }
  .tl-classified-box {
    .classified-box {
      .rit-box h3 {
        font-size: 13px;
      }
    }
    .classi-box:nth-child(odd) .classified-box {
      margin: 7px 0;
    }
    .classi-box:nth-child(even) .classified-box {
      margin: 7px 0 7px auto;
    }
  }
}
@media (min-width: 1200px) {
  .without-carousel {
    width: 31.33% !important;
  }
  .service-header {
    h2 {
      width: 180px;
      font-size: 28px;
    }
  }

  .banner-search-cont {
    width: 300px;
  }
  .tl-classified-box {
    .classified-box {
    }
    .classi-box:nth-child(odd) .classified-box {
      margin: 7px 0;
    }
    .classi-box:nth-child(even) .classified-box {
      margin: 7px 0 7px auto;
    }
  }
  .banner-carousel-gallery {
    .carousel-control-next {
      left: 350px !important;
    }
    .carousel-inner {
      .left-carousel {
        width: 400px;
      }
      .cap-tion {
        width: 650px;
      }
      .custom-cap-tion {
        height: 260px !important;
      }
    }
  }
  .SecondFlexDesign {
    width: 60% !important;
  }
}

@media (min-width: 1400px) {
  .tl-classified-box {
    .classified-box {
    }
    .classi-box:nth-child(odd) .classified-box {
      margin: 7px 0;
    }
    .classi-box:nth-child(even) .classified-box {
      margin: 7px 0 7px auto;
    }
  }
  .banner-carousel-gallery {
    .carousel-control-next {
      left: 350px !important;
    }
    .carousel-inner {
      .left-carousel {
        width: 500px;
      }
      .cap-tion {
        width: 900px;
      }
    }
  }
  .tl-service .service-box .box {
    padding: 80px 20px !important;
    border-radius: 50px !important;
  }
}

@media (min-width: 1600px) {
  .banner-search-cont {
    input {
      width: 90%;
    }
  }
  .service-header {
    h2 {
      width: 210px;
      font-size: 29px;
    }
  }

  .banner-search-cont {
    width: 500px;
  }
}

@media (max-width: 1175px) {
  .hide-cont {
    display: none !important;
    background-color: red !important;
  }
}

//

@media (max-width: 1200px) {
  .without-carousel {
    width: 31.33% !important;
  }
}
@media (max-width: 1240px) {
  .display-custom {
    display: block !important;
    .w-custom {
      width: 191% !important;
      background-color: RED !important;
      // margin-right: 20px;
    }
  }
  .cust-btn {
  }
}

@media (min-width: 990px) {
  .custom-input-flex {
    // background-color: pink !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    justify-content: space-between !important;
    gap: 3px !important;
  }
}

@media (max-width: 990px) {
  .custom-btn-add {
    justify-content: space-between !important;
    width: 100vh !important;
  }
}
// @media (min-width: 9px) {
//   .tl-service .service-box .box1 .box-inner1 .profile-img img {
//     width: 100% !important;
//     margin-left: 0px !important;
//   }
// }
@media (max-width: 967px) {
  .tl-service2 .service-box2 {
    width: 100% !important;
    // align-items: center !important;
    // justify-content: center !important;
  }
  .col-custom-res {
    flex: 0 0 auto !important;
    width: 100% !important;
    // padding-left: 10px !important;
    // padding-right: 10px !important;
    margin-top: 30px;
  }
  .image-abt {
    padding-right: 10px;
    padding-left: 10px;
    display: flex !important;
    flex: auto !important;
    width: 100% !important;
    height: 100% !important;
  }
}
// @media (min-width: 966px) {
//   .tl-service .service-box .box1 .box-inner1 .profile-img img {
//     width: 100% !important;
//     align-items: center !important;
//     justify-content: center !important;
//   }
// }

@media (min-width: 966px) {
  .tl-services2 {
    flex: 0 0 auto !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .tl-service2 .service-box2 {
    flex: 0 0 auto !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 428px) {
  .tl-service2 img .service-box2 {
    height: 200px !important;
    width: 300px !important;
  }
  .ser-caurosel-cont2 {
    height: 200px !important;
    width: 300px !important;
  }
}

@media (max-width: 428px) {
  .ser-caurosel-cont p {
    right: 20% !important;
  }
  .carousel-control-next,
  .carousel-control-prev {
    width: 44% !important;
  }
}

@media (max-width: 1199px) {
  .bg-danger-custon {
    // height: 200px !important;
    width: 500px !important;
  }
}
@media (max-width: 990px) {
  .bg-danger-custon {
    // height: 200px !important;
    width: 300px !important;
  }
}
@media (max-width: 577px) {
  .bg-danger-custon {
    // height: 200px !important;
    width: 200px !important;
  }
}
@media (max-width: 1175px) {
  .image-custom-size {
    height: 300px !important;
    // display: none;
  }
}
// @media (max-width: 785px) {
//   .image-custom-size {
//     height: 200px !important;
//     // display: none;
//   }
// }

@media (max-width: 966px) {
  .comp-hide {
    display: none !important;
  }
  .align-custom {
    justify-content: space-around !important;
  }
}
@media (min-width: 966px) {
  .comp-show {
    display: none !important;
  }
}
