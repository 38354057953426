@import "./Font.scss";
@import "./Theme.scss";

body,
html {
  margin: 0;
  padding: 0;
  font-family: "f1";
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "f3";
}

p {
  font-size: 14px;
}

h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: $black;
}
:focus-visible {
  outline: 0 !important;
}
::placeholder {
  font-size: 13px;
}
ul {
  list-style: none;
  padding: 0;
}

.err {
  color: #ff0000;
}

.bg-primar {
  background-color: $primary !important;
}

.bg-lt-primary {
  background-color: $lt-primary !important;
}

.primary {
  color: $primary;
}

.sec {
  color: $sec;
}

.sec1 {
  color: $sec1;
}

.bg-primary1 {
  background-color: $primary1 !important;
}

.bg-sec {
  background-color: $sec !important;
}

.bg-sec1 {
  background-color: $sec1 !important;
}

.primary1 {
  color: $primary1;
}
.bg-gradient {
  background: linear-gradient($primary, $primary1) !important;
}

.lt-Blue {
  color: $lt-blue;
}

.bg-bg-dark {
  background-color: $black !important;
}

.bg-darkBlue {
  background-color: $Blue !important;
}
.bg-blue1 {
  background-color: $Blue1 !important;
}

.bg-pinks {
  background-color: $pink !important;
}
.bg-red {
  background-color: $red !important;
}

.bg-pink1 {
  background-color: $pink1 !important;
}
.bg-greens {
  background-color: $greens !important;
}

.bg-lt-blue {
  background-color: $lt-blue !important;
}

.bg-lt-blue1 {
  background-color: $lt-blue1 !important;
}

.lt-blue1 {
  color: $lt-blue1;
}

.lt-blue2 {
  color: $lt-blue2;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-orange {
  background-color: $orange;
}
.orange {
  color: $orange;
}
.blue1 {
  color: $Blue1 !important;
}
.pinks {
  color: $pink !important;
}
.pink1 {
  color: $pink1 !important;
}

.greens {
  color: $greens !important;
}

.bg-gray1 {
  background-color: $gray1;
}
.bg-gray2 {
  background-color: $gray2 !important;
}

.bg-gray3 {
  background-color: $gray3 !important;
}

.bg-gray4 {
  background-color: $gray4 !important;
}

.bg-white {
  background-color: $white;
}
.white {
  color: $white !important;
}

.gray {
  color: $gray;
}
.gray1 {
  color: $gray1;
}
.gray3 {
  color: $gray3;
}
.gray4 {
  color: $gray4;
}
//
//
//
//
//opacity-start
.op-100 {
  opacity: 1;
}

.op-80 {
  opacity: 0.8;
}

.op-70 {
  opacity: 0.7;
}

.op-60 {
  opacity: 0.6;
}

.op-50 {
  opacity: 0.5;
}
.op-30 {
  opacity: 0.3;
}
.op-10 {
  opacity: 0.1;
}
.op-0 {
  opacity: 0;
}
//opacity-end
//
//
//
//width-start
.w-95 {
  width: 95%;
  margin: auto;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.w-80 {
  width: 80%;
  margin: auto;
}

.w-70 {
  width: 70%;
  margin: auto;
}

.w-75 {
  width: 75%;
  margin: auto;
}

.w-60 {
  width: 60%;
  margin: auto;
}

.w-65 {
  width: 65%;
  margin: auto;
}

.w-40 {
  width: 40%;
  margin: auto;
}

.w-45 {
  width: 45%;
  margin: auto;
}

.w-30 {
  width: 30%;
  margin: auto;
}

.w-35 {
  width: 35%;
  margin: auto;
}

.w-20 {
  width: 20%;
  margin: auto;
}

.w-25 {
  width: 25%;
  margin: auto;
}

.w-10 {
  width: 10%;
  margin: auto;
}

.w-15 {
  width: 15%;
  margin: auto;
}

.w-5 {
  width: 5%;
  margin: auto;
}

//width-end
//
//
//
//
//height-start
.h-100 {
  height: 100vh !important;
}
.min-h-100 {
  min-height: 100vh !important;
}
//height-end
//font-size-start
//
//
//
//font-size-end
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-35 {
  font-size: 35px !important;
}

//font-family-start

.f1 {
  font-family: "f1";
}
.f2 {
  font-family: "f2";
}

.f3 {
  font-family: "f3";
}

.f4 {
  font-family: "f4";
}

.f5 {
  font-family: "f5";
}

.f6 {
  font-family: "f6";
}

//font-family-end
//
//
//
//flex-concept-start
.as-jc {
  align-items: flex-start;
  justify-content: center;
}
.as-jb {
  align-items: flex-start;
  justify-content: space-between;
}
.ac-jc {
  align-items: center;
  justify-content: center;
}
.ae-jc {
  align-items: end;
  justify-content: center;
}
.ae-jb {
  align-items: end;
  justify-content: space-between;
}
.ae-je {
  align-items: end;
  justify-content: flex-end;
}
.ac-je {
  align-items: center;
  justify-content: flex-end;
}
.ac-js {
  align-items: center;
  justify-content: flex-start;
}
.ae-jb {
  align-items: end;
  justify-content: space-between;
}
.ac-jb {
  align-items: center;
  justify-content: space-between;
}
//flex-concept-end
//
//
//
.s-0 {
  left: 0 !important;
}
.s-3 {
  left: 3px !important;
}
.s-5 {
  left: 5px !important;
}
.s-8 {
  left: 8px !important;
}
.s-10 {
  left: 10px !important;
}
.s-13 {
  left: 13px !important;
}
.t-0 {
  top: 0 !important;
}
.t-5 {
  top: 5px !important;
}
.t-8 {
  top: 8px !important;
}

.cust-btn {
  border: 0;
  background: transparent;
  padding: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.scroll-w::-webkit-scrollbar {
  width: 9px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
  // box-shadow: inset 0 0 10px $primary1;
}

::-webkit-scrollbar-thumb {
  // background: $primary1;
  box-shadow: inset 0 0 10px $primary1;
  border-radius: 10px;
}
.scroll-w::-webkit-scrollbar-thumb {
  // background: $primary1;
  box-shadow: inset 0 0 10px $primary1 !important;
  border-radius: 10px !important;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
// .custom-file-input {
//   border: 1px dashed #c5c5c5;
//   padding: 6px;
//   width: 100%;
// }
.custom-file-input::before {
  content: "Upload Original Certificate";
  display: inline-block;
  background: #f1f7ff;
  border-radius: 3px;
  // padding: 7px 8px;
  outline: none;
  white-space: nowrap;
  color: #7a7a7a;
  font-size: 13px;
  font-family: "f1";
  width: 100%;
  text-align: left;
}
// .custom-file-input:hover::before {
//   border-color: black;
// }
// .custom-file-input:active::before {
//   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
// }
