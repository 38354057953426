@import "../constant/Font.scss";
@import "../constant/Theme.scss";
@import "../constant/keyFrames.scss";

//login-start

//login-end
.body-design {
  background: $gray3;
}
// navbar start
.nav-bar1 {
  box-shadow: 0 0 3px #c6c6c6;
  .company-logo {
    width: 95px;
  }
  .menus-list {
    .menu-categ-title {
      font-family: "f3";
      font-size: 15px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      padding: 6px 13px !important;
      svg {
        font-size: 16px;
      }
    }
    .menu-categ-btn {
      font-family: "f3";
      font-size: 13px;
      color: $primary !important;
      text-transform: uppercase;
      padding: 5px 20px !important;
      display: flex;
      // align-items: center;
      flex-direction: column;
      .activeLine {
        width: 50px;
        height: 3px;
        margin-left: -11px;
        margin-top: 3px;
      }
      svg {
        font-size: 16px;
      }
    }
    .btn-cls {
      position: absolute;
      right: 00px;
      top: 130px;
      width: 50px;
      z-index: 111;
    }
    .sub-categ {
      z-index: 11;
      top: 40px;
      left: 0;
      border-top: 6px solid #e4e4e4;
      overflow-x: scroll;
      .tab-box {
        button {
          padding: 0px 0;
          margin-right: 5px;
          background: $lt-blue;
          width: 200px;
          &:first-child {
            background: $red;
            color: $white !important;
          }
        }
      }
      .menu-box {
        .box {
          padding: 10px;
          button {
            width: 100%;
          }
          &:first-child {
            border-left: 2px solid $orange;
          }
        }
      }
    }
  }

  .nav-sign-in-cont1 {
    position: absolute;
    right: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    padding: 3px 8px;
    background-color: $white;
    border-radius: 100px;
    font-family: "f3";
    font-size: 13px;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }
    img {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }
  }
  .nav-sign-in-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    padding: 3px 8px;
    background-color: $white;
    border-radius: 100px;
    font-family: "f3";
    font-size: 13px;
    svg {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }

    img {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }
  }
}
.nav-bar {
  .company-logo {
    width: 60px;
  }
  .collaps-nav {
    a {
      font-family: "f3";
      font-size: 13px;
      color: $primary !important;
      text-transform: uppercase;
      padding: 5px 20px !important;
    }
    .dropdown-menu {
      a {
        font-family: "f3";
        color: $primary !important;
        text-transform: uppercase;
        padding: 5px 20px !important;
      }
    }

    .nav-bar-collp-cont {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .nav-sign-in-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    padding: 3px 8px;
    background-color: $white;
    border-radius: 100px;
    .nav-link {
      font-family: "f3";
      font-size: 13px;
    }
    svg {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }

    img {
      width: 15px;
      height: 15px;
      border: 1px solid $primary;
      border-radius: 10px;
      margin-right: 7px;
    }
  }
}

// navbar end

.css-t3ipsp-control {
  min-height: auto !important;
  border-top: 1px solid #c5c5c5 !important;
  border-left: 1px solid #c5c5c5 !important;
  border-right: 1px solid #c5c5c5 !important;
  border-bottom: 1px solid #c5c5c5 !important;
  border-radius: 10 !important;
  box-shadow: none !important;
  &:hover {
    min-height: auto !important;
    border-top: 1px solid #c5c5c5 !important;
    border-left: 1px solid #c5c5c5 !important;
    border-right: 1px solid #c5c5c5 !important;
    border-bottom: 1px solid #c5c5c5 !important;
    border-radius: 10 !important;
    box-shadow: none !important;
  }
}
.css-13cymwt-control {
  min-height: 34px !important;
  border: 0 !important;
}
.react-select .css-qbdosj-Input {
  color: #000 !important;
  font-family: "f4" !important;
}
.react-select .css-qbdosj-Input input {
  color: #000 !important;
  font-family: "f4" !important;
}
.css-t3ipsp-control {
  min-height: auto !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  &:hover {
    min-height: auto !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0px solid #c5c5c5 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}
.css-1fdsijx-ValueContainer {
  margin-left: -12px;
  min-height: 34px !important;
}
.css-1te13x-Ua {
  padding: 0 !important;
  input {
    &::placeholder {
      font-size: 11px !important;
      font-family: "f2";
      color: #fff !important;
    }
  }
}
.css-b62m3t-container {
  border: 0 !important;
}
.css-tj5bde-Svg {
  width: 15px;
  color: $gray;
}
.css-1dimb5e-singleValue {
  font-size: 15px;
  font-family: "f2";
  color: $black !important;
  &::placeholder {
    font-size: 11px !important;
    font-family: "f2";
    color: #fff !important;
  }
}
.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

//footer start

.class-active {
  font-family: "f2";
  border-bottom: 3px solid #ffab07 !important;
}

.service-active {
  font-family: "f2";
  border-bottom: 3px solid #3ebbaf !important;
}

.jobs-active {
  font-family: "f2";
  border-bottom: 3px solid #8833d9 !important;
}

.explore-active {
  font-family: "f2";
  border-bottom: 3px solid #fa42a7 !important;
}

.class-list-cont {
  li {
    // width: 110px !important;
  }
}
.footer-one-sec {
  padding: 20px 5px;
  background-color: #8497b0;
  .footer-one-inner-cont {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;

    button {
      border: none;
      background-color: transparent;
      padding: 5px 0;
      font-family: "f2";
      font-size: 15px;
      color: white;

      span {
        color: $primary;
      }
    }

    ul {
      width: 210px;
      margin: 15px auto;
      li {
        //   // width: 65px;
        //   // margin: 5px 0;
        //   // font-family: "f1";
        //   // font-size: 12px;
        //   // color: white;
      }
      .footer-btns {
        color: #fff;
      }
      .footer-btns:hover {
        color: $primary1;
      }
      margin-bottom: 0;
    }
    p {
      width: 210px;
      margin: 5px auto;
    }
  }
}
.middle-footer-cont {
  min-height: 150px;
  display: flex;
}
.middle-footer-inner-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: flex-start;

    svg {
      margin-top: 3px;
    }
  }

  div {
    display: flex;
    align-items: flex-start;
    // height: fit-content;
    min-width: 200px;
    // min-height: 50px;
    margin: 5px auto;

    svg {
      margin-right: 4px;
      color: $white;
      height: 15px;
      width: 15px;
    }
  }

  img {
    width: 113px;
    filter: invert(1) grayscale(1) brightness(4);
  }

  p {
    color: $white;
    margin-right: auto;
    margin-bottom: 0;
    max-width: 200px;
    font-size: 14px;
    font-family: "f2";
  }
}

.footer-last-cont {
  background-color: #8497b0;
  max-height: 150px;
}

.last-footer-inner-cont {
  display: flex;
  flex-wrap: wrap;

  div {
    min-height: 20px;
  }
  button {
    border: none;
    background-color: transparent;
  }
  img {
    width: 20px;
    height: 20px;
    margin: auto;
  }
  p,
  a {
    color: $white;
    font-family: "f2";
    font-size: 14px;
  }
}

// footer end

//banner start
.hide-arrow-slide {
  .carousel-control-prev-icon,
  .carousel-control-next-icon,
  .carousel-indicators {
    display: none;
  }
}
.caros-box {
  img {
    width: auto !important;
    max-width: 100%;
    height: 100%;
  }
}
.ser-caurosel-cont {
  margin: 15px 0;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
  .carousel-inner {
    width: 460px;
    padding: 10px;
    .carousel-item {
      border-radius: 10px;
      // box-shadow: 0 0 3px #afafaf;
      // border: 12px solid #fff;
      /* width: 460px; */
      height: 297px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .carousel-indicators {
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    filter: invert(100%);
  }

  p {
    position: absolute;
    border-radius: 3px;
    font-family: "f2";
    font-size: 15px;
    padding: 6px 10px;
    background-color: #fff;
    right: 10%;
    bottom: 10%;
    z-index: 1;
  }
}
.banner-search-cont {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 5%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    font-size: 30px;
    margin-right: 15px;
  }
  input {
    font-family: "f2";
    color: #000;
    font-size: 16px;
    padding: 9px 0 9px 18px;
    background: transparent;
    border: none;
    width: 100%;
  }
}
.banner-img-cont {
  width: inherit;

  // height: 70%;
}

.carousel-item {
  transition: opacity 1s ease-in-out !important;
}
li {
  transition: opacity 1s ease !important;
}

// banner end

// classfied-start

// services start
.service-list-banner-cont {
  .carousel-caption {
    left: auto !important;
    right: 10%;
    bottom: 7% !important;
    background-color: #0000000f;
    padding: 5px 10px !important;
  }
}

.service-detail-sec {
  font-family: "f2";
  font-size: 17px;
  h5 {
    font-size: 24px;
    font-family: "f4";
  }
  .bread-crumb {
    button,
    p {
      font-size: 15px;
      font-family: "f3";
      color: $lt-primary;
    }
    button:hover {
      font-size: 15px;
      color: $primary1;
    }
  }
  .contact-button {
    background: $pink;
    box-shadow: 2px 2px 0px 1px $pink1;
    margin: 10px 0;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin-right: 10px;
    svg {
      font-size: 17px;
      margin-right: 8px;
    }
    span {
      font-size: 17px;
    }
  }
  .contact-button span:first-child {
    border-right: 1px solid #ffff;
  }

  .btn-primary {
    padding: 1px 5px !important;
    border: none !important;
  }
  .desc-txt {
    p {
      text-align: justify;
      font-size: 14px;
    }
    .bullets-icon {
      font-size: 20px;
      color: $pink;
    }
  }
  h6 {
    font-size: 14px;
    color: #000;
    span {
      font-size: 10px;
    }
  }
  .price {
    font-size: 25px;
    color: $primary1;
  }
  p {
    font-size: 14px;
    color: #000;
    svg {
      font-size: 18px;
      margin-top: -5px;
    }
  }
  .post-by {
    width: 100%;
    p {
      font-size: 15px;
      font-family: "f2";
      color: $primary;
    }
  }
  .social-media-icons {
    button {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 18px;
      }
    }
  }
}

.service-detail-img-cont {
  width: 50px;
  height: 50px;
}

.see-more-btn-cont {
  button {
    color: $primary1;
    font-family: "f2";
    font-size: 15px;
    border: none;
    background-color: transparent;
  }
}

.class-service-cont {
  select {
    background-color: #f0f5f7;
    cursor: pointer;
    color: dimgray;
    padding: 7px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
  }
}

.service-card {
  cursor: pointer;
  min-height: 110px;
  font-family: "f2";
  font-size: 13px;
  margin: 15px 0;
  background-color: $white;
  padding: 14px 10px 10px 20px;
  border: 1px solid #ecedf2;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.3s ease;
  width: 100%;

  img {
    max-width: 100%;
    border-radius: 5px;
    width: 90px;
    height: 90px;
  }

  svg {
    height: 18px;
    width: 20px;
  }
}

.service-card-time-cont {
  p {
    background: rgba(25, 103, 210, 0.15);
    color: #1967d2;
    line-height: 15px;
    padding: 5px 20px;
    border-radius: 50px;
  }
}

// services end

.service-header {
  h2 {
    width: 130px;
    font-size: 20px;
  }
  p {
    width: 60%;
    font-size: 14px;
  }
}
// classified end
//home-classified-start
.tl-classified {
  .tl-box {
    width: 90%;
    margin: auto;
    .item {
      .box {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        .img-box {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .detail-btm {
          color: #fff;
          padding: 10px;
          text-align: left;
          .time-box {
            margin: -50px 10px auto auto;
            background: $primary;
            margin-bottom: 5px;
            position: relative;
            z-index: 1;
            width: fit-content;
            width: -moz-fit-content;
            padding: 10px 18px;
            border-radius: 7px;
            box-shadow: 0 0 2px 1px #000;
            h4 {
              font-size: 15px;
              color: #fff;
              font-family: "f3";
              text-align: center;
            }
            img {
              width: 70px;
            }
          }
          h5 {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-family: "f3";
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            font-size: 14px;
            margin-bottom: 4px;
            color: #fff;
            font-family: "f1";
            span {
              font-size: 14px;
              color: #fff;
              font-family: "f3";
            }
          }
          button {
            font-size: 13px;
            margin-bottom: 8px;
            padding: 5px 15px;
            color: #fff;
            background: $primary;
            font-family: "f1";
            border-radius: 20px;
            border: 1px solid #fff;
            svg {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
//home-classified-end

//home-service-start
.tl-service {
  .service-box {
    .box {
      width: 100%;
      overflow: hidden;
      border-radius: 50px;
      padding: 10px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 2px 1px #afafaf;
      .curve {
        width: 90%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .box-inner {
        z-index: 1;
        .profile-img {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          overflow: hidden;
          margin: auto;
          // border: 1px solid #000;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // margin-left: -30px;
          }
        }
        h4 {
          margin-top: 20px;
          font-size: 18px;
          margin-bottom: 2px;
          color: #000;
          font-family: "f4";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h5 {
          font-size: 14px;
          margin-bottom: 5px;
          color: $primary;
          font-family: "f3";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-size: 14px;
          margin-bottom: 4px;
          color: $primary;
          font-family: "f1";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            font-size: 14px;
            color: #000;
            font-family: "f3";
          }
        }
        button {
          font-size: 14px;
          margin-bottom: 8px;
          padding: 5px 15px;
          color: #fff;
          background: $primary;
          font-family: "f1";
          border-radius: 20px;
        }
      }
    }
    .box1 {
      overflow: hidden;
      border-radius: 40px;
      padding: 50px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px #e4e4e4;
      .curve {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .box-inner {
        z-index: 1;
        .profile-img {
          width: 125px;
          height: 125px;
          border-radius: 20px;
          overflow: hidden;
          margin: auto;
          img {
            width: 160%;
            margin-left: -35px;
          }
        }
        h4 {
          margin-top: 20px;
          font-size: 18px;
          margin-bottom: 2px;
          color: #000;
          font-family: "f4";
        }
        h5 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #000;
          font-family: "f3";
        }
        p {
          font-size: 14px;
          margin-bottom: 4px;
          color: #000;
          font-family: "f1";
          span {
            font-size: 14px;
            color: #000;
            font-family: "f3";
          }
        }
        button {
          font-size: 14px;
          margin-bottom: 8px;
          padding: 5px 15px;
          color: #fff;
          background: $primary;
          font-family: "f1";
          border-radius: 20px;
        }
      }
      .box-inner1 {
        z-index: 1;
        .profile-img {
          width: 125px;
          height: 125px;
          border-radius: 20px;
          overflow: hidden;
          margin: auto;
          img {
            width: 100%;
          }
        }
        h4 {
          margin-top: 20px;
          font-size: 23px;
          margin-bottom: 5px;
          color: #000;
          font-family: "f4";
        }
        h5 {
          font-size: 17px;
          margin-bottom: 5px;
          color: #000;
          font-family: "f3";
        }
        p {
          font-size: 17px;
          margin-bottom: 4px;
          color: #000;
          font-family: "f1";
          span {
            font-size: 14px;
            color: #000;
            font-family: "f3";
          }
        }
        button {
          font-size: 14px;
          margin-bottom: 8px;
          padding: 5px 15px;
          color: #fff;
          background: $primary;
          font-family: "f1";
          border-radius: 20px;
        }
      }
    }
  }
}
//home-service-end

//home-explore-start
.tl-explore {
  .tl-box {
    width: 90%;
    margin: auto;
    .box {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      .img-box {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .detail-btm {
        color: #fff;
        padding: 10px;
        text-align: left;
        .media-clip {
          position: absolute;
          z-index: 12;
          // top: -135px;
          top: -90px;
          right: 5px;
          background: #fff;
          border-radius: 5px;
          padding: 10px;
          box-shadow: 0 0 2px 1px #b1b1b1;
          button {
            padding: 3px 6px;
            img {
              width: 20px;
            }
            p {
              color: $primary !important;
              font-size: 15px !important;
              font-family: "f3" !important;
              margin-left: 10px;
            }

            a {
              color: $primary !important;
              font-size: 15px !important;
              font-family: "f3" !important;
              margin-left: 10px;
            }
          }
        }
        .time-box {
          margin: -30px 10px auto auto;
          background: $primary;
          margin-bottom: 5px;
          position: relative;
          z-index: 1;
          width: fit-content;
          width: -moz-fit-content;
          padding: 10px 15px;
          border-radius: 7px;
          box-shadow: 0 0 2px 1px #000;
          cursor: pointer;
          h4 {
            font-size: 15px;
            color: #fff;
            font-family: "f3";
            text-align: center;
          }
          img {
            width: 26px;
          }
        }
        h5 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #fff;
          font-family: "f3";
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h4 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #ddd;
          font-family: "f2";
          margin-top: 10px;
        }
        p {
          font-size: 14px;
          margin-bottom: 10px;
          color: #ddd;
          font-family: "f1";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            font-size: 14px;
            color: #c5c5c5;
            font-family: "f3";
          }
        }
        .post-explor {
          button {
            font-size: 13px;
            margin-bottom: 8px;
            padding: 5px 15px;
            color: #fff;
            background: #000;
            font-family: "f1";
            border-radius: 20px;
            svg {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
//home-explore-end

//classified-page-start
//classified-banner-start
.tl-clasified-page {
  padding: 0 5%;
  margin: auto;
  .list-banner {
    width: 100%;
    margin: auto;
    img {
      width: 100%;
    }
  }
}
//classified-banner-end
//classified-tab-end
.tl-classif-tab {
  margin-top: 10px;
  box-shadow: 0 0 5px 0px #25242434;
  padding: 10px;
  margin-bottom: 10px;
  .title-h4 {
    // width: 85px;
    height: 32px;
    p {
      font-size: 17px;
      font-family: "f4";
    }
    .line1 {
      width: 50px;
      height: 3px;
      background: $black;
      margin-left: 8px;
    }
  }

  .tab-height {
    height: 32px;
  }

  .tab-txt {
    overflow: hidden;
    padding: 0 10px;
    .active {
      border-bottom: 3px solid #000;
    }
    button {
      font-size: 16px;
      font-family: "f2";
      color: $black;
      padding: 2px 0px 0px;
      margin: 0 15px 10px;
    }
  }
  .tab-txtServices {
    overflow: hidden;
    padding: 0 10px;
    .active {
      border-bottom: 3px solid #000;
    }
    button {
      font-size: 16px;
      font-family: "f2";
      color: $black;
      padding: 2px 0px 0px;
      margin: 0 15px 10px;
    }
  }
  .more-btn {
    background: $orange;
    width: 85px;
    height: 32px;
    p {
      font-size: 15px;
      font-family: "f4";
      color: $black;
    }
    svg {
      font-size: 18px;
      color: $black;
      margin-left: 5px;
    }
  }
}
//classified-tab-end
//classified-list-box-start
.tl-classified-box {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  .classified-box {
    background-color: white;
    width: 98%;
    border-radius: 10px;
    .lft-img-box {
      width: 420px;
      height: 200px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // margin-left: -65px;
      }
    }
    .rit-box {
      width: 100%;
      padding-left: 10px;
      h3 {
        font-size: 19px;
        font-family: "f5";
        color: $primary;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      h4 {
        font-size: 16px;
        font-family: "f4";
        color: $orange;
        margin: 8px 0;
      }
      h5 {
        font-size: 18px;
        font-family: "f3";
        color: $black;
        margin: 12px 0;
      }
      .explore-btn {
        background: $primary;
        width: 130px;
        color: #fff;
        padding: 6px 8px;
        align-items: center;
        justify-content: space-between;
        border-radius: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
        p {
          font-size: 13px;
          font-family: "f2";
        }
      }
      .post-by {
        width: 100%;
        p {
          font-size: 15px;
          font-family: "f2";
          color: $primary1;
          align-items: center;
        }
        svg {
          font-size: 15px;
        }
      }
    }
  }
}
//classified-list-box-end
//pagination-tab-start
.pagination-tab {
  padding-bottom: 20px;
  .pagination-tab-inner {
    background: #fff;
    border-radius: 10px;
    .active {
      color: $white !important;
      background: #1967d2;
    }
    button {
      font-size: 15px;
      width: 45px;
      height: 45px;
      font-family: "f3";
      color: $primary;
      border: 0;
    }
    li {
      font-size: 15px;
      width: 45px;
      height: 45px;
      font-family: "f3";
      color: $primary;
      border: 0;
    }
  }
}
//pagination-tab-end
//sort-by-start
.sort-by {
  position: relative;
  .show-text {
    font-size: 15px;
    font-family: "f2";
    background: $lt-primary;
    color: $white;
    border-radius: 5px;
    padding: 5px 15px;
  }
  .show-btn {
    font-size: 15px;
    font-family: "f2";
    background: $lt-primary;
    color: $white;
    border-radius: 5px;
    margin: 0px 0px 6px 2px;
  }
  .download-box {
    position: absolute;
    top: 40px;
    left: 0;
    border: 1px solid #000;
    border-radius: 4px;
    width: 100px;
    background: #fff;
    z-index: 11;
    button {
      border-bottom: 1px solid #d8d8d8 !important;
      width: 100%;
      font-size: 14px;
      font-family: "f2";
    }
  }
}
//sort-by-end
.result-txt {
  font-size: 18px;
  font-family: "f2";
}
//classified-page-end

//classified-tab-start
.relate-post-tab {
  button {
    border: 2px solid $gray4;
    padding: 10px 19px;
    font-size: 17px;
    font-family: "f3";
    color: $black;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    span {
      border: 2px solid $white;
      border-radius: 5px;
      padding: 5px;
      width: 165px;
      display: block;
    }
  }
  .active {
    background: $gray4;
    span {
      border: 1px solid $black;
      border-radius: 5px;
      padding: 7px 20px;
      display: block;
    }
  }
}
.relate-box {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.relate-box {
  .classified-box {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
  }
}
//classified-tab-end

//gallery-start
.gallery-post-tl {
  .gallery-text {
    font-size: 24px;
    padding: 7px;
    width: 130px;
    display: flex;
    color: #fff;
    font-family: "f3";
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.current-photo {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #fff;
  padding: 2px 5px;
}
.banner-carousel-gallery {
  box-shadow: 0 0 10px #c7c7c7;
  padding: 5px;
  .line2 {
    width: 4px;
    height: 150px;
    background: #000;
  }
  .carousel-control-next {
    width: auto !important;
    opacity: 1 !important;
    padding: 10px;
  }
  .carousel-control-prev {
    width: auto !important;
    opacity: 1 !important;
    padding: 10px;
    left: 20px;
  }
  .carousel-control-next-icon {
    padding: 10px;
    width: 20px;
    height: 20px;
    filter: invert(1) grayscale(1) contrast(5);
    opacity: 1 !important;
  }
  .carousel-control-prev-icon {
    padding: 10px;
    width: 20px;
    height: 20px;
    filter: invert(1) grayscale(1) contrast(5);
    opacity: 1 !important;
  }
  .carousel-inner {
    .left-carousel {
      overflow: hidden;
      border: 9px solid #fff;
      border-radius: 15px;

      img {
        width: 100%;
      }
    }
    .cap-tion {
      padding: 10px;
      h5 {
        font-size: 21px;
        font-family: "f3";
        color: $black;
      }
      p {
        font-size: 15px;
        text-align: justify;
      }
    }
  }
}
//gallery-end

//profile-start
// .body-profile {
//   overflow-y: scroll;
// }
.profile-left {
  width: 300px;
  height: 100vh;
  background: $sec;
  padding: 0px 0 10px;
  z-index: 1;
  .cls-btn {
    color: $white;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    margin: 0 0 0 0px;
    padding: 7px;
    z-index: 11;
    height: auto;
    opacity: 1;
    transition: 0.2s all linear;
  }
  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 10px 0;
    img {
      // width: 280px;
      width: 150px;
    }
  }
  button {
    height: 42px;
    font-size: 15px;
    font-family: "f2";
    color: $white;
    margin: 10px 0;
    padding-left: 8px;
    padding-right: 12px;
    p {
      margin-left: 15px;
      transition: 0.2s all linear;
    }
  }
  .active {
    background-color: $sec1;
  }
}
.profile-right {
  overflow-y: scroll;
  padding: 20px 50px;
  h5 {
    font-size: 22px;
    font-family: "f4";
    color: $black;
  }
  .form-groups {
    width: 49%;
    h4 {
      font-size: 14px;
      font-family: "f4";
      color: $black;
      margin-top: 11px;
    }
    input,
    select {
      width: 100%;
      border: 1px solid #d4d4d4;
      padding: 8px 15px;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 15px;
      font-family: "f2";
      color: $black;
    }
  }
}
.btn-style {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "f3";
  font-size: 16px;
}
//profile-end

//login-Screen-start
.FirstFlex {
  width: 100%;
  background-image: url("../../img/login.jpg");
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  .FirstFlexDesign {
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    display: grid;
    padding: 41px;
    width: 400px;

    .ImageDesign {
      align-items: center;
      justify-content: center;
      display: flex;
      filter: invert(2) brightness(5) saturate(1) grayscale(1);
      img {
        width: 150px;
      }
    }
  }
  .h-i {
    height: 33px !important;
  }

  .ImageDesignMultivendor {
    display: flex;
    text-align: center;
    width: 100%;
    .MultiVendorFontSize {
      font-size: 22px;
      font-family: "f2";
      margin-top: 15px;
      color: #fff;
      width: 320px;
    }
  }

  .ImageDesignMultivendor {
    display: flex;
    text-align: center;
    width: 100%;
  }

  .MultiVendorFontSize {
    font-size: 26px;
  }
}
.street-placeholder {
  position: absolute;
  left: 38px;
  top: 13px;
  font-size: 13px !important;
  font-family: "f2";
  color: #7c7c7c !important;
  z-index: 1;
  pointer-events: none;
}
.SecondFlex {
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  overflow: scroll;
  .LoginFont {
    font-family: "f3";
    font-size: 16px;
    margin-bottom: 20px;
  }
  .count-sec {
    p {
      color: $black;
      font-family: "f3";
      font-size: 15px;
    }
    button {
      color: $black;
      font-family: "f3";
      font-size: 15px;
    }
  }
  .SecondFlexDesign {
    width: 80%;
    &::placeholder {
      font-size: 15px;
    }
    .MobileNumberTextInput {
      padding: 8px 10px;
      width: 100%;
      border: 1px solid #bbb;
      border-radius: 5px;
      color: $black;
      font-family: "f2";
      font-size: 15px;
      margin-bottom: 28px;
    }
    .react-select {
      padding: 3px 0px 3px 40px;
      width: 100%;
      border: 1px solid #bbb;
      border-radius: 5px;
      color: $black;
      font-family: "f2";
      font-size: 13px;
      margin-bottom: 28px;
    }
    .form-groups {
      .MobileNumberTextInput1 {
        padding: 10px 10px 10px 40px;
        width: 100%;
        border: 1px solid #bbb;
        border-radius: 5px;
        color: $black;
        font-family: "f2";
        font-size: 15px;
        margin-bottom: 28px;
      }
      .MobileNumberTextInput2 {
        padding: 10px 10px 10px 65px;
        width: 100%;
        border: 1px solid #bbb;
        border-radius: 5px;
        color: $black;
        font-family: "f2";
        font-size: 15px;
        margin-top: -3px;
        margin-bottom: 28px;
      }
      svg {
        opacity: 0.3;
        font-size: 24px;
      }
    }
    .check-box {
      // width: 18px;
      width: 24px;
      height: 16px;
      // background: $black;
      background: #0c2348;
      border-radius: 3px;
      cursor: pointer;
      svg {
        font-size: 15px;
        color: $white;
      }
    }
    .CheckBoxText {
      color: $black;
      font-family: "f2";
      font-size: 13px;
      margin-top: -3px;
      margin-left: 8px;
    }
    .btn-submit {
      border-radius: 5px;
      font-size: 16px;
    }
    .NotRegisteredText {
      font-size: 14px;
    }
  }

  .LoginText {
    color: black;
    font-family: "f4";
    font-size: 27px;
    margin-bottom: 20px;
  }
  .LoginText1 {
    color: black;
    font-family: "f4";
    font-size: 27px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
//login-Screen-end
//
//
//
//
//job-starts
//job-list-start
.job-box {
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0px 1px 2px 0px #666666;
  align-items: center;
  justify-content: center;
  .box-inner {
    z-index: 1;
    width: 100%;
    .line3 {
      width: 1px;
      background-color: $sec;
      height: 150px;
      margin: 0 10px;
    }
    .profile-img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // margin-left: -30px;
      }
    }
    .rit-box-jox {
      width: 65%;
      h4 {
        font-size: 16px;
        margin-bottom: 8px;
        color: #000;
        font-family: "f4";
      }
      h5 {
        font-size: 14px;
        margin-bottom: 8px;
        color: $primary;
        font-family: "f3";
      }
      p {
        font-size: 14px;
        margin-bottom: 8px;
        color: $primary;
        font-family: "f2";
        span {
          font-size: 14px;
          color: #000;
          font-family: "f3";
        }
      }
      button {
        font-size: 14px;
        margin-bottom: 8px;
        padding: 5px 15px;
        color: #fff;
        background: $sec;
        font-family: "f2";
        border-radius: 20px;
      }
    }
  }
}
.service_hidden {
  height: 297px;
  overflow: hidden;
}
.img-cont {
  box-shadow: 0 0 5px #c8c8c8;
  border-radius: 10px;
}
.job-left-img {
  box-shadow: 0 0 5px #c8c8c8;
  border-radius: 10px;
  width: 300px;
  padding: 30px;
}
//job-list-end
//job-end

.newPostImage {
  width: 110px;
  height: 110px;
  border: 1px solid #222;
}

.addMenuImg {
  width: 310px;
  height: 100px;
}

.addMenuImg1 {
  width: 100px;
  height: 100px;
}

.newPostView {
  position: relative;
}

.closeIcon {
  right: 5px;
  top: 3px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.closeIcon-edit {
  right: 1px;
  top: 34px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.closeIcon-company {
  right: 0px;
  top: 0px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.closeIcon-company1 {
  left: 0px;
  top: 0px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.closeIcon-company2 {
  left: 120px;
  top: 8px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.deleteIcon-profile {
  left: 0px;
  top: 0px;
  font-size: 19px !important;
  background: #c91010;
  position: absolute;
  color: white;
  border-radius: 2px;
  padding: 2px;
}

.textAreaServices {
  font-family: "f2";
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
  width: 80%;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  &::placeholder {
    font-family: "f2";
    font-size: 14px;
  }
}
.textAreaServices-galleryadd {
  font-family: "f2";
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  width: 100%;
}
.upload-img {
  width: 110px;
  height: 110px;
  margin-right: 12px;
}

.add-btn {
  font-size: 13px;
  font-family: "f2";
  background: $lt-primary;
  color: $white;
  border-radius: 5px;
  margin: 0px 0px 6px 2px;
  height: 34px;
  width: 80px;
}

.add-btn1 {
  font-size: 13px;
  font-family: "f2";
  background: $lt-primary;
  color: $white;
  border-radius: 5px;
  margin: 22px 0px 0px 0px;
  height: 39px;
  width: 80px;
  align-self: center;
}

.tl-tb-box {
  button,
  p {
    padding: 7px 15px;
    color: #fff;
    font-family: "f2";
    border-radius: 5px;
    font-size: 16px;
  }
  .active {
    background: $white;
    color: $black;
  }
}
.edit-delet-icon-tablist {
  padding: 10px;
  svg {
    font-size: 20px;
  }
}

.error-state {
  color: red;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 50px;
  font-size: small;
  font-family: "f1";
}

.error-state-profile {
  color: red;
  position: absolute;
  right: 0px;
  bottom: -10px;
  font-size: small;
  font-family: "f1";
}

.uploadText {
  font-size: 18px;
}

.pdfFormat {
  font-size: 10px;
}

.error-state-genderBlood {
  color: red;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 44px;
  font-size: small;
  font-family: "f1";
}

.error-state-login {
  color: red;
  position: absolute;
  bottom: 12px;
  right: 0px;
  font-size: small;
  font-family: "f2";
}

.error-state-otp {
  color: red;
  position: absolute;
  bottom: 90px;
  right: 0px;
  font-size: small;
  font-family: "f2";
}

.number-container {
  position: relative;
}
.number-container p {
  position: absolute;
  top: 9px;
  left: 10px;
  font-family: "f1";
}

.SecondFlex .SecondFlexDesign .number-container {
  .MobileNumberTextInput {
    padding-left: 37px;
  }
}

.numbervalue91 {
  position: absolute;
  // top: 11px;
  top: 8px;
  left: 40px;
  font-weight: bold;
  // color: #757575;
}

.contactUsInput {
  padding-left: 35px !important;
}

.contactUsInputValue {
  position: absolute;
  top: 41px;
  left: 10px;
  font-weight: bold;
  color: #757575;
}
.uploadpdfdesign {
  border-radius: 10px;
}

.mobileInput-profile {
  padding-left: 35px !important;
}

.mobileInput-profile-p {
  position: absolute;
  top: 41px;
  left: 10px;
  font-weight: bold;
}

.timeDayInput {
  position: absolute;
  top: 41px;
  left: 10px;
  padding-left: 5px;
}

//Login Logo
.companyLogo {
  width: 100px;

  margin: 0px auto 30px auto;
}
.companyLogoRegister {
  width: 100px;
  margin: 10px auto 0px auto;
}

.customPopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
  background-color: #0000003f;
}

.customPopupDesign {
  width: 400px;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: grid;
}

.box {
  // display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.mcp_image {
  .prof-imag {
    .prf-img {
      width: 110px;
      height: 110px;
      overflow: hidden;
      margin: auto;
      border-radius: var(--bs-border-radius-lg) !important;
    }
  }
}

.food_btn {
  width: 7.4rem;
  height: 1.7rem;
  border: none;
  border-radius: 50px 50px 0px 50px;
  background: #ffab06;

  .food-play {
    border: 1px solid white;
    border-radius: 100%;
    background: white;
    width: 23px;
    height: 22px;
    margin-top: -1px;
    position: absolute;
    margin-left: 4px;
  }

  .food-menu {
    color: #fff;
    margin-left: 30px;
  }
}

.food-table {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 15.5rem;
  overflow: hidden;
  .food-table-tab {
    thead {
      border-radius: 10px 10px 0px 0px;
      background: #4f6d7a;
      th {
        color: #fff;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
      }
    }

    tbody {
      tr {
        border: #fff;

        td {
          color: #000;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 1rem;
        }
      }
    }
  }
}

// New Style

.box2 {
  padding: 0px !important;
}

.tl-classified .tl-box .item .box .detail-btm {
  padding: 0 !important;
}

.btn-texts {
  font-family: "f3";
  font-size: 7px;
  align-self: "center";
}

// LOADER
.loader-icon {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 33333;
  background: #fff;
  .loader-icon1 {
    background: url("../../img/lodergif.gif") no-repeat;
    background-size: contain;
    width: 100px;
    height: 100px;
  }
}

.image-croping {
  align-items: normal !important;
}

.custom-h3 {
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  display: -webkit-box !important;
}
.custom-dis {
  white-space: wrap !important;
  text-overflow: ellipsis !important;
  overflow-y: scroll !important;
}
.carousel-cont {
  justify-content: center !important;
  overflow-y: scroll !important;
}
.dis-cont {
  overflow-y: scroll !important;
}

.custom-cap-tion {
  height: 280px !important;
}
.round-custom {
  // font-size: 10px !important;
  font-size: 15px !important;
  font-family: "f2";
  border-color: "bg-primar";
  color: #fff;
  margin: 10px 10px;
}

.clasifide--cont {
  align-items: center !important;
  justify-content: center !important;
  background-color: red !important;
  display: flex;
  flex-direction: column;
}

.icon-cont {
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  z-index: 100 !important;
  height: 100vh !important;
  width: 100vh !important;
  position: absolute !important;
  float: right !important;
  background-color: red($color: #6160601e);
}

.loginpopup {
  height: 180px;
  position: fixed;
  top: calc(50vh - 90px);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.loginpopuplayout .modal-body {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  height: 100%;
  width: 95%;
}

.loginpopup1 {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #00000066;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  right: 0;
  left: 0;
  bottom: 0;
}

.loginpopuplayout1 .modal-body {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgb(255, 255, 255);
  position: relative;
  border-radius: 5px;
  height: 100%;
  width: 95%;
}

.w-500 {
  width: 600% !important;
}

.form-groups2 {
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  h4 {
    font-size: 14px;
    font-family: "f4";
    color: $black;
    margin-top: 11px;
  }
  input,
  select {
    width: 100%;
    border: 1px solid #d4d4d4;
    padding: 8px 15px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
    font-family: "f2";
    color: $black;
  }
}

.center-custom {
  justify-content: end !important;
}

.display-custom {
  width: 100% !important;
  background-color: #92929200 !important;
  // margin-right: 20px;
}
.custpm-height-carosel {
  height: 290px !important;
  width: 399px !important;
}
.loderbg {
  // background-color: ;
  backdrop-filter: blur(8px);
}
.tl-service2 {
  // height: 70% !important;
  // width: 50% !important;
  // display: flex !important;
}
.tl-service2 img {
  height: 100% !important;
  width: 450px !important;
  object-fit: cover;
}
.service-box2 {
  height: 297px !important;
  width: 450px !important;
}
.bg-danger-custon {
  background-color: #fff;
  // padding: 10px 20px;
  border-radius: 10px;
  height: auto;
  width: 300px;
  p {
    font-family: "f2";
    font-size: 13px;
    color: #000000;
    padding: 5px;
  }
  p:hover {
    color: rgb(0, 0, 255);
    background-color: #c7c7c74b;
  }
}

.banner-img-cont2 {
  height: 650px !important;
  width: 200px !important;
}
.h-50-cust {
  width: 446px !important;
  height: 283px !important;
}
.ser-caurosel-cont2 .carousel-inner {
  height: 297px !important;
  width: 450px !important;
}
.ser-caurosel-cont2 .carousel-inner .carousel-item img {
  width: 100%;
  height: 243px;
}
.carousel-control-next,
.carousel-control-prev {
  color: red !important;
}

.flot-monal {
  .modal-dialog {
    margin: auto;
    max-width: 500px;
  }

  .modal-header {
    .btn-close {
      padding: 0;
      border: 0;
      right: 6px;
      top: 5px;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 7px;
      opacity: 1;
      /* background-color: aliceblue; */
      /* filter: invert(1); */
    }
  }
}

.audi-form {
  h5 {
    color: $primary;
    text-align: center;
  }

  label {
    margin: 5px 0;
    font-size: 14px;
  }

  .form-control {
    padding: 12px 15px;
    box-shadow: 0px 10px 40px rgb(48 124 255 / 15%);
    font-size: 13px;
    border: 0;
  }
}

.error-state-enquiry {
  color: red;
  position: absolute;
  right: 5px;
  bottom: -20px;
  font-size: small;
  font-family: "f1";
}

.image-abt {
  height: 356px;
  width: 461px;
}
.font-custom {
  .activeLine {
    width: 120px;
    height: 2px;
    margin-left: 3px;
    margin-top: 3px;
    background-color: orange;
  }
}

//menu
.dropdownmenu {
  float: left;
  position: relative;
  list-style: none;
}

.submenu p.sub {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 10px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
  line-height: 30px;
  word-break: keep-all;
}

.dropdownmenu p.menu {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu:hover {
  // background: linear-gradient(to left, #bff098, #6fd6ff) !important;
  background-color: whitesmoke !important;
}

.dropdownmenu:hover,
.dropdownmenu:hover:focus {
  // background: linear-gradient(to left, #bff098, #6fd6ff) !important;
  background-color: whitesmoke !important;
}

.submenu {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  transition: 0.5s;
  float: none;
  align-self: center;
  width: 100%;
}

.dropdownmenu:hover .submenu {
  opacity: 1;
  top: 35px;
  visibility: visible;
  z-index: 100;
  // background: linear-gradient(to left, #bff098, #6fd6ff) !important;
  background-color: whitesmoke !important;
}

.submenu p.sub:hover {
  background-color: #0c2348 !important;
  color: white !important;
}
// clacifide menu end

//menu2 start

.dropdownmenu2 {
  float: left;
  position: relative;
  list-style: none;
  width: auto;
}

.dropdownmenu2 p.menu2 {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu2 p.menu2:hover {
  // background: linear-gradient(to left, #fda085, #f6d365);
  background-color: whitesmoke !important;
}

.dropdownmenu2:hover,
.dropdownmenu2:hover:focus {
  // background: linear-gradient(to left, #fda085, #f6d365) !important;
  background-color: whitesmoke !important;
  color: #fff !important;
}

// menu3
.dropdownmenu3 {
  float: left;
  position: relative;
  list-style: none;
}

.submenu3 p.sub3 {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 10px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
  line-height: 30px;
  word-break: keep-all;
}

.dropdownmenu3 p.menu3 {
  color: #0c2348 !important;
  display: block;
  // padding: 5px 5px !important;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu3:hover {
  // background: linear-gradient(to left, #ea8d8d, #a890fe) !important;
  background-color: whitesmoke !important;
}

.dropdownmenu3:hover,
.dropdownmenu3:hover:focus {
  // background: linear-gradient(to left, #ea8d8d, #a890fe) !important;
  background-color: whitesmoke !important;
}

.submenu3 {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  transition: 0.5s;
  float: none;
  align-self: center;
  width: 100%;
}

.dropdownmenu3:hover .submenu3 {
  opacity: 1;
  top: 35px;
  visibility: visible;
  z-index: 100;
  // background: linear-gradient(to left, #ea8d8d, #a890fe) !important;
  background-color: whitesmoke !important;
}

.submenu3 p.sub3:hover {
  // background-color: gray !important;
  background-color: #0c2348 !important;
  color: #ffff !important;
}
// menu3

// manu 4

.dropdownmenu4 {
  float: left;
  position: relative;
  list-style: none;
  width: 100px !important;
}

.submenu4 p.sub4 {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 10px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
  line-height: 30px;
  word-break: keep-all;
}

.dropdownmenu4 p.menu4 {
  color: #0c2348 !important;
  display: block;
  // padding: 5px 5px !important;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu4:hover {
  // background: linear-gradient(to left, #d8b5ff, #1eae98) !important;
  background-color: whitesmoke !important;
}

.dropdownmenu4:hover,
.dropdownmenu4:hover:focus {
  // background: linear-gradient(to left, #d8b5ff, #1eae98) !important;
  background-color: whitesmoke !important;
}

.submenu4 {
  left: -20;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  transition: 0.5s;
  float: none;
  align-self: center;
  width: 100% !important;
}

.dropdownmenu4:hover .submenu4 {
  opacity: 1;
  top: 35px;
  visibility: visible;
  z-index: 100;
  // background: linear-gradient(to left, #d8b5ff, #1eae98) !important;
  background-color: whitesmoke !important;
  // width: 100%;
}

.submenu4 {
}

.submenu4 p.sub4:hover {
  background-color: #0c2348 !important;
  color: #fff !important;
}

// menu 4

// menu5
.dropdownmenu5 {
  float: left;
  position: relative;
  list-style: none;
}

.submenu5 p.sub5 {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 10px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
  line-height: 30px;
  word-break: keep-all;
}

.dropdownmenu5 p.menu5 {
  color: #0c2348 !important;
  display: block;
  // padding: 5px 5px !important;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu5:hover {
  // background: linear-gradient(to left, #c6ea8d, #fe90af) !important;
  background-color: whitesmoke !important;
}

.dropdownmenu5:hover,
.dropdownmenu5:hover:focus {
  // background: linear-gradient(to left, #c6ea8d, #fe90af) !important;
  background-color: whitesmoke !important;
}

.submenu5 {
  left: -10;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  transition: 0.5s;
  width: 100% !important;
}

.dropdownmenu5:hover .submenu5 {
  opacity: 1;
  top: 35px;
  visibility: visible;
  z-index: 100;
  // background: linear-gradient(to left, #c6ea8d, #fe90af) !important;
  background-color: whitesmoke !important;
}

.submenu5 {
  float: none;
  align-self: center;
}

.submenu5 p.sub5:hover {
  background-color: #0c2348 !important;
  color: #fff !important;
}
// menu5

//menu about

.dropdownmenu6 {
  float: left;
  position: relative;
  list-style: none;
}

.dropdownmenu6 {
  float: left;
  position: relative;
}

.dropdownmenu6 p.menu6 {
  color: #0c2348 !important;
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: "f3";
  align-items: center;
  text-decoration: none;
  transition: all 0.25s ease;
}
.dropdownmenu6 p.menu6:hover {
  // background: linear-gradient(to left, #fdc585, #f665f6);
  background-color: whitesmoke !important;
}

.dropdownmenu6:hover,
.dropdownmenu6:hover:focus {
  // background: linear-gradient(to left, #fdc585, #f665f6) !important;
  background-color: whitesmoke !important;
}

//menu about

// .box-cont {
//   height: 50px;
//   width: 200px;
//   background-color: red !important;
//   align-items: center !important;
//   justify-content: center !important;
//   float: right;
//   position: absolute;
//   bottom: 30px;
//   right: 20px;
// }

// Popup start
.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }
  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 5vh;
    width: 70%;
    height: 87vh;
    overflow: scroll;
  }
  .left-popup1 {
    height: 100%;
    background: #e7eefa;
    align-items: center;
    justify-content: end;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    .imges {
      width: 100%;
      height: 80%;
      background: #f4f8ff;
      box-shadow: 0px 15px 10px -8px #d5e5fb;
      -webkit-box-shadow: 0px 15px 10px -8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-right: -50px;
      justify-content: center;
      overflow: hidden;
      img {
        width: 80%;
        margin: auto;
      }
    }
    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;
      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }
      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }
  .left-popup {
    z-index: 1;
    width: 450px;
    height: 100%;
    background: #e7eefa;
    align-items: center;
    justify-content: end;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    .imges {
      width: 460px;
      height: 80%;
      background: #f4f8ff;
      box-shadow: 0px 20px 20px -8px #d5e5fb;
      -webkit-box-shadow: 0px 30px 20px -8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-right: -50px;
      justify-content: center;
      img {
        width: 80%;
        margin: auto;
      }
    }
    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;
      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }
      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }
  .rit-pop {
    padding-left: 80px;
    background: #f4f8ff;
    height: 100%;
    h2 {
      color: #000;
      font-family: "f4";
      margin-top: 30px;
      span {
        color: $primary1;
        font-family: "f2";
        font-size: 15px;
        margin-left: 10px;
      }
    }
    p {
      margin-bottom: 6px;
    }
    h3 {
      font-size: 20px;
      color: #000;
      font-family: "f4";
      margin-bottom: 15px;
      margin-top: 20px;
    }
    .color-box {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .qunt-btn {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-family: "f1";
      border-radius: 100px;
      line-height: 4px;
      box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      background: #ffffff;
    }
  }
  .modal-cls-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-family: "f1";
    border-radius: 100px;
    line-height: 4px;
    z-index: 111;
    // box-shadow:
    //   inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    //   inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    //   4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    // -webkit-box-shadow:
    //   inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    //   inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    //   4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    background: transparent !important;
    img {
      width: 55%;
    }
  }
}

.font-custom {
  font-size: 16px;
  font-family: "f4";
}

// Popup end
