$primary: #0c2348;
$primary1: #003261;
$sec: #4f6d7a;
$sec1: #c0d6df;
$lt-primary: #008cff;
$lt-blue: #f1f7ff;
$Blue: #1850a8;
$Blue1: #0082ca;
$lt-blue1: #cde2fe;
$lt-blue2: #e3efff;
$gray: #787b7f;
$gray1: #262626;
$gray2: #353535;
$gray3: #e5e5e5;
$gray4: #cbcbcb;
$white: #fff;
$black: #000;
$orange: #ff9a34;
$red: #f82a2a;
$pink: #fb457d;
$pink1: #d9446f;
$greens: #009688;
// navbar
$BACKGROUND: #45494e;
$COLOR1: #fda085;
$COLOR1g: #f6d365;
$COLOR2: #bff098;
$COLOR2g: #6fd6ff;
$COLOR3: #ea8d8d;
$COLOR3g: #a890fe;
$COLOR4: #d8b5ff;
$COLOR4g: #1eae98;
$COLOR5: #c6ea8d;
$COLOR5g: #fe90af;

$inr: ₹;
