@font-face {
  font-family: "f1";
  src: url("../../fonts/Montserrat/Montserrat-Light.ttf");
}
@font-face {
  font-family: "f2";
  src: url("../../fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "f3";
  src: url("../../fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "f4";
  src: url("../../fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "f5";
  src: url("../../fonts/Montserrat/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "f6";
  src: url("../../fonts/Montserrat/Montserrat-BlackItalic.ttf");
}
