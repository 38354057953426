@keyframes mymove {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes backgrounds {
  0% {
    background-color: rgba(0, 22, 41, 0.8);
  }
  80% {
    background-color: rgba(0, 22, 41, 0.8);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@keyframes mymove1 {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(-360deg);
  }
}
@keyframes mymove2 {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  50% {
    transform: rotateY(-180deg);
    opacity: 1;
  }
  80% {
    transform: rotateY(-288deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(-360deg);
    opacity: 0;
  }
}

@keyframes mymove3 {
  0% {
    transform: rotateY(0deg);
    opacity: 0;
  }
  50% {
    transform: rotateY(-180deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(-360deg);
    opacity: 1;
    visibility: hidden;
  }
}
